<template>
    <div v-if="loading" class="h-screen w-full flex flex-col items-center justify-center bg-white bg-opacity-75 inset-0 z-50 fixed">
        <p class="font-bold uppercase text-xl mb-4">Loading...</p>
        <img src="~/assets/images/loading.svg">
    </div>
</template>
<script>
export default {
    data: () => ({
        loading: false
    }),
    methods: {
        start() {
            this.loading = true
        },
        finish() {
            this.loading = false
        }
    }
}
</script>