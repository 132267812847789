<template>
  <div :class="`embed_responsive embed_responsive--${aspectRatio}`">
    <iframe :src="src" :class="`embed_responsive__item ${extraClass}`" />
  </div>
</template>

<script>
export default {
  name: 'EmbedIframe',
  props: {
    src: {
      default: '',
      required: true,
      type: String
    },
    aspectRatio: {
      default: '16by9', // or: 21by9 / 4by3 / 1by1
      required: false,
      type: String
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>
