<template>
  <tbody>
    <table-tr v-for="(row, rowIndex) in rows" :key="'table-body-row-' + rowIndex" :data="row" />
  </tbody>
</template>

<script>
import TableTr from './tr'
export default {
  name: 'TableTbody',
  components: { TableTr },
  props: {
    rows: {
      default: () => [],
      required: false,
      type: Array
    }
  }
}
</script>
