<template>
  <div
    class="product__item border border-solid border-gray-medium rounded mb-8 flex flex-wrap py-4"
  >
    <div class="w-full tablet:w-1/5 px-4 flex items-center justify-center">
      <div v-if="data.images && data.images.length > 0" class="product__image">
        <nuxt-link :to="localePath(link)">
          <img
            :src="`${data.images[0]}`"
            :alt="`${data.sku} - ${data.product.name}`"
          />
        </nuxt-link>
      </div>
      <div v-else class="product__image">
        <nuxt-link :to="localePath(link)">
          <img
            :alt="`${data.sku} - ${data.product.name}`"
            src="/images/placeholder.jpg"
          />
        </nuxt-link>
      </div>
    </div>
    <div class="w-full tablet:w-1/2 px-4">
      <div class="product__info p-4">
        <header-h5 :content="`${data.product.name}`" mt="0" mb="0" />
        <header-h6
          v-if="data.differentation"
          v-html="data.differentation.replace(/(?:\r\n|\r|\n)/g, '<br>')"
          mt="0"
          mb="0"
          weight="normal"
          extra-class="italic"
        />
        <p>
          <strong v-t="'components.products.sku'" /><br>
          <strong
            v-if="data.to_be_anounced === 0"
            v-html="data.sku"
            class="text-red"
          />
          <strong v-else v-t="'components.products.tba'" class="text-red" />
        </p>
        <p v-if="data.oems && data.oems.length > 0 && loggedIn">
          <span v-html="$t('components.products.more_oem')"></span>
        </p>
        <p v-if="data.oems && data.oems.length > 0 && !loggedIn">
          <strong> OEM: </strong><br>
          <strong v-for="(oem, index) in data.oems" class="text-red">{{
            data.oems.length > 3
              ? index === (0 || 1 || 2)
                ? `${data.oems[0]}, ${data.oems[1]}, ${data.oems[2]}`
                : index === 3
                ? `...`
                : ``
              : index === data.oems.length - 1
              ? oem
              : `${oem}, `
          }}</strong>
        </p>
      </div>
    </div>
    <div class="w-full tablet:w-3/10 px-4">
      <div v-if="data.to_be_anounced === 0" class="px-4 tablet:px-8 pb-4">
        <p>
          <strong v-t="'components.products.stock_status'" /><br>
          <span
            :class="
              `bg-${
                stockStatus === 'low'
                  ? 'red'
                  : 'green'
              } text-white py-1 px-2 rounded text-sm`
            "
          >
            <span v-t="'components.products.in_stock'" />:
            {{ stock > 10 ? '10+' : stock }}
          </span>
        </p>
        <p>
          <strong v-t="'components.products.stock_status_fr'" /><br>
          <span
            :class="
              `bg-${
                stockStatusFR === 'low'
                  ? 'red'
                  : 'green'
              } text-white py-1 px-2 rounded text-sm`">
            <span v-t="'components.products.in_stock'" />:
            {{ stockFR > 10 ? '10+' : stockFR }}
          </span>
        </p>
        <p>
          <strong v-t="'components.products.stock_status_ie'" /><br>
          <span
            :class="
              `bg-${
                stockStatusIE === 'low'
                  ? 'red'
                  : 'green'
              } text-white py-1 px-2 rounded text-sm`">
            <span v-t="'components.products.in_stock'" />:
            {{ stockIE > 10 ? '10+' : stockIE }}
          </span>
        </p>
        <div class="flex">
          <p>
            <strong v-t="'components.products.price'" /><br>
            <span>
              {{
                price > 0
                  ? new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: currency
                    }).format(price)
                  : $t('components.products.request')
              }}
            </span>
          </p>
          <button
            v-if="$auth.loggedIn"
            @click="addToFavorites"
            class="text-lg block my-auto ml-auto button-icon">
            <font-awesome-icon
              v-if="isInFavorites"
              :icon="['fas', 'heart']"/>
            <font-awesome-icon
              v-else
              :icon="['far', 'heart']"/>
          </button>
        </div>
      </div>
      <div v-else class="px-4 tablet:px-8 pb-4">
        <strong v-t="'components.products.tba_message'" /><br>
      </div>
      <div class="px-4 w-full">
        <button-primary
          :text="$t('components.products.read_more')"
          :path="link"
          extra-class="block text-sm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import portal from '../../service/portal';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'ProductItem',
  props: {
    data: {
      default: () => {},
      required: true,
      type: Object
    },
    // eslint-disable-next-line vue/require-default-prop
    link: {
      required: true,
      type: Object || String
    }
  },
  computed: {
    isInFavorites () {
      let favoritesInStore = this.$store.getters.getFavoriteItems;
      return favoritesInStore.indexOf(this.data.id) > -1;
    }
  },
  data() {
    let price = 0;
    let stock = 0;
    let stockFR = 0;
    let stockIE = 0;
    let currency = 'EUR';
    if (this.data.price_object === null) {
      price = 0;
      stock = 0;
    } else {
      const priceObject = JSON.parse(this.data.price_object.price_object);
      const priceList = this.$store.state.priceList;
      price = priceObject['Pricelist' + priceList];
      stock = priceObject.stocklevel;
      stockFR = priceObject.stocklevelfr;
      stockIE = priceObject.stocklevelirl;
      currency = CommonUtils.getCurrencyForPriceList(priceList);
    }
    const stockStatus = parseInt(stock) === 0 ? 'low' : parseInt(stock) > 5 ? 'high' : 'normal';
    const stockStatusFR = parseInt(stockFR) === 0 ? 'low' : parseInt(stockFR) > 5 ? 'high' : 'normal';
    const stockStatusIE = parseInt(stockIE) === 0 ? 'low' : parseInt(stockIE) > 5 ? 'high' : 'normal';
    price = price ?? 0;

    return {
      loggedIn: this.$auth.loggedIn,
      price,
      stock,
      stockStatus,
      stockFR,
      stockStatusFR,
      stockIE,
      stockStatusIE,
      currency
    }
  },
  methods: {
    addToFavorites () {
      if (!this.loggedIn) {
        return;
      }

      let requestParams = {
        user_id: this.$auth.user.user_id,
        product_id: this.data.id
      };

      let headers = {
        'Content-Type': 'application/json'
      }

      if (this.isInFavorites) {
        portal.post(`favourites/remove`, requestParams, headers)
        .then((response) => {
          this.$store.commit('REMOVE_FAVORITE_ITEM', this.data.id);
        });
        return;
      }

      portal.post(`favourites/add`, requestParams, headers)
      .then((response) => {
        this.$store.commit('ADD_FAVORITE_ITEM', this.data.id);
      });
    }
  }
}
</script>
