<template>
  <div class="inline">
    <div v-if="type === 'submit-form'">
      <button
        :class="`button button--secondary ${extraClass}`"
        type="submit"
        :disabled="isDisabled">
        <span v-html="text" />
      </button>
    </div>
    <div v-else-if="type === 'login'">
      <button
        @click="$parent.login()"
        :class="`button button--secondary ${extraClass}`"
        :disabled="isDisabled">
        <span v-html="text" />
      </button>
    </div>
    <div v-else-if="type === 'register'">
      <button
        @click="$parent.register()"
        :class="`button button--secondary ${extraClass}`"
        :disabled="isDisabled">
        <span v-html="text" />
      </button>
    </div>
    <div v-else-if="type === 'submit'">
      <button
        @click="$parent.submit()"
        :class="`button button--secondary ${extraClass}`"
        :disabled="isDisabled">
        <span v-html="text" />
      </button>
    </div>
    <div v-else>
      <div v-if="path">
        <nuxt-link
          :to="localePath(path)"
          :class="`button button--secondary ${extraClass}`">
          <span v-html="text" />
        </nuxt-link>
      </div>
      <div v-else>
        <a
          :href="link"
          :class="`button button--secondary ${extraClass}`"
          target="_blank">
          <span v-html="text" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonSecondary',
  props: {
    type: {
      default: 'link',
      required: false,
      type: String
    },
    path: {
      default: '',
      required: false,
      type: String
    },
    link: {
      default: '',
      required: false,
      type: String
    },
    text: {
      default: '',
      required: true,
      type: String
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
