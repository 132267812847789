<template>
  <div class="news__item">
    <div v-if="imageUrl && buttonLink">
      <img :src="imageUrl" :alt="title" />
    </div>
    <div v-else-if="imageUrl">
      <nuxt-link
        :to="
          localePath({
            name: 'news-slug',
            params: { slug: string_to_slug(slug) }
          })
        "
        ><img :src="imageUrl" :alt="title"
      /></nuxt-link>
    </div>
    <div class="news__content">
      <header-h6
        :content="title"
        color="black"
        casing="uppercase"
        leading="none"
        mt="0"
        mb="0"
      />
      <div v-html="content" class="my-4" />
      <div v-if="buttonLink">
        <button-primary
          v-if="externalButton"
          :link="buttonLink"
          :text="buttonText"
        />
        <!--        <button-primary v-else :path="buttonLink" :text="buttonText" />-->
      </div>
      <div v-else>
        <button-primary
          :path="{
            name: 'news-slug',
            params: { slug: string_to_slug(slug) }
          }"
          :text="$t('components.news.read_more')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsItem',
  props: {
    title: {
      default: '',
      required: true,
      type: String
    },
    slug: {
      default: '',
      required: true,
      type: String
    },
    content: {
      default: 'left',
      required: true,
      type: String
    },
    imageUrl: {
      default: '',
      required: false,
      type: String
    },
    buttonLink: {
      default: '',
      required: false,
      type: String
    },
    buttonText: {
      default: '',
      required: false,
      type: String
    },
    externalButton: {
      default: false,
      required: false,
      type: Boolean
    }
  },
  methods: {
    string_to_slug(str) {
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()

      // remove accents, swap ñ for n, etc
      const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
      const to = 'aaaaaaeeeeiiiioooouuuunc------'

      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

      return str
    }
  }
}
</script>
