<template>
  <h5
    :class="
      `font-${family} font-${weight} text-${color} text-${align} ${casing} tracking-${tracking} leading-none tablet:leading-${leading} mt-${mt} mb-${mb} ${extraClass}`
    "
    v-html="content"
  />
</template>

<script>
export default {
  name: 'HeaderH5',
  props: {
    content: {
      default: '',
      required: true,
      type: String
    },
    color: {
      default: 'black',
      required: false,
      type: String
    },
    weight: {
      default: 'medium',
      required: false,
      type: String
    },
    align: {
      default: 'left',
      required: false,
      type: String
    },
    casing: {
      default: 'normal-case',
      required: false,
      type: String
    },
    tracking: {
      default: 'normal',
      required: false,
      type: String
    },
    family: {
      default: 'body',
      required: false,
      type: String
    },
    leading: {
      default: 'normal',
      required: false,
      type: String
    },
    mt: {
      default: '4',
      required: false,
      type: String
    },
    mb: {
      default: '1',
      required: false,
      type: String
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>
