<template>
  <div
    v-if="data.product"
    class="product__item border border-solid border-gray-medium rounded mb-8 flex flex-wrap py-4"
  >
    <div class="px-4">
      <div class="mt-0 mb-4 overflow-hidden">
        <div class="title" style="height: 75px;">
          <strong v-html="data.product.name" />
        </div>
      </div>
      <div v-if="data.images && data.images.length > 0" class="product__image">
        <nuxt-link :to="localePath(link)">
          <img
            :src="`${data.images[0]}`"
            :alt="`${data.sku} - ${data.product.name}`"
            loading="lazy"
            height="533"
            width="800"
          />
        </nuxt-link>
      </div>
      <div v-else class="product__image">
        <nuxt-link :to="localePath(link)">
          <img
            :alt="`${data.sku} - ${data.product.name}`"
            src="/images/placeholder.jpg"
            loading="lazy"
            height="600"
            width="800"
          />
        </nuxt-link>
      </div>
      <div class="sku">
        <p class="pb-0 mb-0">
          <strong>
            AP Air Part number:
          </strong>
          <br>
          <strong v-html="data.sku" style="color:red"></strong>
        </p>
      </div>
    </div>
    <div class="text-right w-full pr-6">
      <button-primary
        :text="$t('components.products.read_more')"
        :path="link"
        extra-class="text-sm"
      />
    </div>
  </div>
</template>

<script>
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'ProductQuick',
  props: {
    data: {
      default: () => {},
      required: true,
      type: Object
    },
    link: {
      default: () => {},
      required: true,
      type: Object
    }
  },
  data() {
    let price = 0
    let stock = 0
      let stockFR = 0;
    let currency = 'EUR'
    if (this.data.price_object === null) {
      price = 0
      stock = 0
    } else {
      const priceObject = JSON.parse(this.data.price_object.price_object);
      const priceList = this.$store.state.priceList;
      price = priceObject['Pricelist' + priceList];
      stock = priceObject.stocklevel;
      stockFR = priceObject.stocklevelfr;
      currency = CommonUtils.getCurrencyForPriceList(priceList);
    }
    const stockStatus = parseInt(stock) === 0 ? 'low' : parseInt(stock) > 5 ? 'high' : 'normal'
    const stockStatusFR = parseInt(stockFR) === 0 ? 'low' : parseInt(stockFR) > 5 ? 'high' : 'normal';

    return {
      price,
      stock,
      stockStatus,
        stockFR,
        stockStatusFR,
      currency
    }
  }
}
</script>
