<template>
  <div class="product__item flex flex-wrap">
    <div class="w-full px-4">
      <header-h1
        :content="`${data.product.name}`"
        casing="uppercase"
        mt="0"
        mb="0"
        extraClass="text-h2"
      />

      <header-h2
        v-if="oems.length"
        :content="`${$t('components.products.replaces_oems')}: ${oems.join(', ')}`"
        mt="3"
        mb="0"
        extraClass="text-left text-lg oems-h2"
      />
    </div>
    <div class="w-full tablet:w-1/2 pt-4 px-4">
      <div class="mb-4">
        <client-only>
          <div
            v-if="data.to_be_anounced"
            class="product__image relative">
            <img
              :src="'/images/tba-thumbnail.jpg'"
              :alt="imgAltTxt"
              class="w-full border border solid border-gray-medium rounded cursor-pointer"
            />
          </div>
          <div v-else-if="data.images.length === 1" class="product__image relative">
            <img
              :src="`${data.images[0]}`"
              :alt="imgAltTxt"
              @click="setIndex(0)"
              class="w-full border border solid border-gray-medium rounded cursor-pointer"
            />
          </div>
          <div v-else>
            <agile>
              <div
                v-for="(image, index) in data.images"
                :key="'image-' + index"
                @click="setIndex(index)"
              >
                <img
                  :src="image"
                  :alt="`${imgAltTxt} - ${index}`"
                  class="w-full border border solid border-gray-medium rounded cursor-pointer"
                />
              </div>
              <template slot="prevButton">
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
              </template>
              <template slot="nextButton">
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
              </template>
            </agile>
          </div>
        </client-only>
      </div>
      <div class="-mx-2 flex flex-wrap">
        <div
          v-for="(video, index) in data.videos"
          :key="'video-' + index"
          class="w-1/3 px-2 mb-4 cursor-pointer"
        >
          <img
            @click="$modal.show(`video_${index}`)"
            :alt="imgAltTxt"
            src="/images/youtube.jpg"
            class="border border solid border-gray-medium rounded"
          />
        </div>
        <div
          v-for="(factsheet, index) in data.factsheets"
          :key="'factsheet-' + index"
          class="w-1/3 px-2 cursor-pointer"
        >
          <a :href="factsheet" target="_blank"
            ><img
              :alt="imgAltTxt"
              src="/images/download.jpg"
              class="border border solid border-gray-medium rounded"
          /></a>
        </div>
      </div>
      <client-only>
        <gallery
          :images="data.images"
          :index="index"
          @close="onClose"/>
      </client-only>
    </div>
    <div class="w-full tablet:w-1/2 px-4">
      <div class="product__info p-4">
        <template v-if="data.to_be_anounced">
          <div class="flex flex-wrap -mx-4">
            <div class="w-full px-4">
              <p class="font-medium">
                {{ $t('components.products.tba_part_not_in_range') }}
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <p
            v-if="data.product.description"
            v-html="data.product.description.replace(/(?:\r\n|\r|\n)/g, '<br>')"
          />
          <div class="flex flex-wrap">
            <div class="price-details-wrapper grid grid-cols-2 w-full">
              <div class="price-details-item">
                <strong v-t="'components.products.stock_status'" /><br>
                <div :class="`bg-${parseInt(stock) === 0 ? 'red' : 'green'} text-white py-1 px-2 rounded text-sm`">
                  <div v-if="parseInt(stock) === 0">
                    <span v-t="'components.products.nostock'" />
                  </div>
                  <div v-else>
                    <span v-t="'components.products.in_stock'" />:
                    {{ stock > 10 ? '10+' : stock }}
                  </div>
                </div>
              </div>
              <div class="price-details-item">
                <strong v-t="'components.products.stock_status_fr'" /><br>
                <div :class="`bg-${parseInt(stockFR) === 0 ? 'red' : 'green'} text-white py-1 px-2 rounded text-sm`">
                  <div v-if="parseInt(stockFR) === 0">
                    <span v-t="'components.products.nostock'" />
                  </div>
                  <div v-else>
                    <span v-t="'components.products.in_stock'" />:
                    {{ stockFR > 10 ? '10+' : stockFR }}
                  </div>
                </div>
              </div>
              <div class="price-details-item">
                <strong v-t="'components.products.stock_status_ie'" /><br>
                <div :class="`bg-${parseInt(stockIE) === 0 ? 'red' : 'green'} text-white py-1 px-2 rounded text-sm`">
                  <div v-if="parseInt(stockIE) === 0">
                    <span v-t="'components.products.nostock'" />
                  </div>
                  <div v-else>
                    <span v-t="'components.products.in_stock'" />:
                    {{ stockIE > 10 ? '10+' : stockIE }}
                  </div>
                </div>
              </div>
            </div>
            <div class="price-details-wrapper flex flex-wrap w-full my-4">
              <div class="price-details-item">
                <strong>
                  <template v-if="$auth.loggedIn">
                    {{ $t('components.products.myPrice') }}
                  </template>
                  <template v-else>
                    {{ $t('components.products.price') }}
                  </template>
                </strong>
                <br>
                <span>
                  <template v-if="price > 0">
                    {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: currency }).format(price) }}
                  </template>
                  <template v-else>
                    {{ $t('components.products.request') }}
                  </template>
                </span>
              </div>
              <div
                v-if="$auth.loggedIn"
                class="price-details-item flex">
                <div class="mr-4">
                  <strong v-t="'components.products.price'" /><br>
                  <span>
                    <template v-if="priceRetail > 0">
                      {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: retailCurrency }).format(priceRetail) }}
                    </template>
                    <template v-else>
                      {{ $t('components.products.request') }}
                    </template>
                  </span>
                </div>
                <button
                  @click="addToFavorites"
                  class="text-lg block my-auto ml-auto button-icon tablet:w-1/10 px-2">
                  <font-awesome-icon
                    v-if="isInFavorites"
                    :icon="['fas', 'heart']"/>
                  <font-awesome-icon
                    v-else
                    :icon="['far', 'heart']"/>
                </button>
              </div>
            </div>
          </div>
        </template>
        <p>
          <strong v-t="'components.products.sku'" /><br>
          <strong
            v-if="data.to_be_anounced === 0"
            v-html="data.sku"
            class="text-red"
          />
          <strong v-else v-t="'components.products.tba'" class="text-red" />
        </p>
        <template
          v-if="
            !data.to_be_anounced &&
            (data.product.part_specification_1 ||
            data.product.part_specification_2 ||
            data.product.part_specification_3)">
          <p>
            <strong v-t="'components.products.specifications'" />
          </p>
          <ul class="list-disc">
            <li
              v-if="data.product.part_specification_1"
              v-html="data.product.part_specification_1.replace(/(?:\r\n|\r|\n)/g, '<br>')">
            </li>
            <li
              v-if="data.product.part_specification_2"
              v-html="data.product.part_specification_2.replace(/(?:\r\n|\r|\n)/g, '<br>')">
            </li>
            <li
              v-if="data.product.part_specification_3"
              v-html="data.product.part_specification_3.replace(/(?:\r\n|\r|\n)/g, '<br>')">
            </li>
          </ul>
        </template>

        <div v-if="data.oems.length > 0">
          <p>
            <strong v-t="'components.products.oem'" />
          </p>
          <div v-if="loggedIn">
            <div v-for="oem in Object.values(data.oems).slice(0, 3)" class="">
              {{oem}}
            </div>
            <template class="inline" v-if="Object.values(data.oems).length > 3">
                ...
            </template>
          </div>
          <p v-else v-html="$t('components.products.more_oem')" />
        </div>
        <div
          v-if="data.to_be_anounced === 0"
          class="px-2 tablet:px-4 w-full mt-8">
          <div class="flex flex-wrap">
            <div class="w-2/3 tablet:w-3/4 px-1">
              <button
                @click="inCart"
                class="button button--primary block text-sm" >
                <span>
                  <font-awesome-icon :icon="['fas', 'shopping-basket']" />&nbsp;&nbsp;{{ $t('components.products.quick_cart') }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex flex-wrap -mx-4">
          <div class="w-full px-4 py-5 border border solid border-red rounded mt-10">
            <header-h6
              :content="$t('components.products.tba_help_us_identify_title')"
              align="center"
              size="large"
              extraClass="text-xl"
              mt="0">
            </header-h6>
            <p
              v-html="$t('components.products.tba_help_us_identify_text')"
              class="text-center font-medium mb-2">
            </p>
            <p class="text-center mt-2 mb-0">
              <small v-html="$t('components.products.tba_help_us_identify_fine_print')"></small>
            </p>
          </div>
        </div>
      </div>
    </div>
    <client-only>
      <modal :height="'auto'" :scrollable="true" name="quickEnquiry">
        <div class="v--modal-top-right text-3xl pr-4">
          <button @click="$modal.hide('quickEnquiry')">
            &times;
          </button>
        </div>
        <div class="v--modal-content px-6 py-4">
          <header-h3 :content="$t('components.products.quick_cart')" mt="0" />
          <p v-t="'components.products.quick_cart_description'" />
          <form @submit.prevent="quickEnquiry">
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.company_name')}`"
                for-id="form_field_company_name"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="company_name"
                :value="company_name"
                :required="true"
                :placeholder="`${$t('components.account.form.company_name')} *`"
                name="company_name"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.first_name')} *`"
                for-id="form_field_first_name"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="first_name"
                :value="first_name"
                :required="true"
                :placeholder="`${$t('components.account.form.first_name')} *`"
                name="first_name"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.last_name')} *`"
                for-id="form_field_last_name"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="last_name"
                :value="last_name"
                :required="true"
                :placeholder="`${$t('components.account.form.last_name')} *`"
                name="last_name"
              />
            </div>
            <div v-if="$i18n.locale === 'en'" class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.house_number')} *`"
                for-id="form_field_house_number"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="house_number"
                :value="house_number"
                :required="true"
                :placeholder="`${$t('components.account.form.house_number')} *`"
                name="house_number"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.street')} *`"
                for-id="form_field_street"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="street"
                :value="street"
                :required="true"
                :placeholder="`${$t('components.account.form.street')} *`"
                name="street"
              />
            </div>
            <div v-if="$i18n.locale !== 'en'" class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.house_number')} *`"
                for-id="form_field_house_number"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="house_number"
                :value="house_number"
                :required="true"
                :placeholder="`${$t('components.account.form.house_number')} *`"
                name="house_number"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.postal_code')} *`"
                for-id="form_field_postal_code"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="postal_code"
                :value="postal_code"
                :required="true"
                :placeholder="`${$t('components.account.form.postal_code')} *`"
                name="postal_code"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.city')} *`"
                for-id="form_field_city"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="city"
                :value="city"
                :required="true"
                :placeholder="`${$t('components.account.form.city')} *`"
                name="city"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.country')} *`"
                for-id="form_field_country"
                extra-class="block mb-2 hidden"
              />
              <div class="form_item">
                <form-input
                  v-model="country"
                  :value="country"
                  :required="true"
                  :placeholder="`${$t('components.account.form.country')} *`"
                  name="country"
                />
              </div>
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.phone')}`"
                for-id="form_field_phone"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="phone"
                :value="phone"
                :required="false"
                :placeholder="`${$t('components.account.form.phone')}`"
                type="tel"
                name="phone"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.email')} *`"
                for-id="form_field_email"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="email"
                :value="email"
                :required="true"
                :placeholder="`${$t('components.account.form.email')} *`"
                type="email"
                name="email"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.order_ref')}`"
                for-id="form_field_order_ref"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="order_ref"
                :required="false"
                :placeholder="`${$t('components.account.form.order_ref')}`"
                name="order_ref"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.remarks')}`"
                for-id="form_field_remarks"
                extra-class="block mb-2 hidden"
              />
              <form-textarea
                v-model="remarks"
                :required="false"
                :placeholder="`${$t('components.account.form.remarks')}`"
                name="remarks"
              />
            </div>

            <div>
              <p>
                {{ succesMessage }}
              </p>
            </div>

            <div class="mt-4 pr-4 text-right">
              <button-primary :text="$t('form.send')" type="submit-form" />
            </div>
          </form>
        </div>
      </modal>
      <modal
        v-for="(video, index) in data.videos"
        :key="'modal-video-' + index"
        :height="'auto'"
        :scrollable="true"
        :name="`video_${index}`"
      >
        <div class="v--modal-top-right text-3xl pr-4">
          <button @click="$modal.hide(`video_${index}`)">
            &times;
          </button>
        </div>
        <div class="v--modal-content px-6 py-4">
          <iframe
            :src="`//www.youtube.com/embed/${getYoutubeId(video.url)}`"
            width="560"
            height="315"
            frameborder="0"
            allowfullscreen
          />
        </div>
      </modal>
    </client-only>
  </div>
</template>

<script>
import portal from '../../service/portal';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'ProductDetail',
  props: {
    data: {
      default: () => {},
      required: true,
      type: Object
    }
  },
  computed: {
    isInFavorites () {
      let favoritesInStore = this.$store.getters.getFavoriteItems;
      return favoritesInStore.indexOf(this.data.id) > -1;
    },
    imgAltTxt () {
      let txt = `${this.data.sku} - ${this.data.product.name}`;
      if (this.data.oems.length) {
        txt += ` (${this.data.oems.join(', ')})`;
      }
      return txt;
    }
  },
  data() {
    let price = 0;
    let priceRetail = 0;
    let stock = 0;
    let stockFR = 0;
    let stockIE = 0;
    let currency = 'EUR';
    let retailCurrency = 'EUR';
    if (this.data.price_object === null) {
      price = 0;
      stock = 0;
      priceRetail = 0;
    } else {
      const priceObject = JSON.parse(this.data.price_object.price_object);
      const priceList = this.$store.getters.getPriceList;
      price = priceObject['Pricelist' + priceList];
      stock = priceObject.stocklevel;
      stockFR = priceObject.stocklevelfr;
      stockIE = priceObject.stocklevelirl;
      currency = CommonUtils.getCurrencyForPriceList(priceList);

      price = price || 0;

      if (this.$auth.loggedIn) {
        const retailPiceList = this.$store.getters.getRetailPriceList;
        priceRetail = priceObject['Pricelist' + retailPiceList];

        retailCurrency = CommonUtils.getCurrencyForPriceList(retailPiceList);
        priceRetail = priceRetail || 0;
      } else {
        priceRetail = price;
        retailCurrency = currency
      }
    }
    const stockStatus = parseInt(stock) === 0 ? 'low' : parseInt(stock) > 5 ? 'high' : 'normal';
    const stockStatusFR = parseInt(stockFR) === 0 ? 'low' : parseInt(stockFR) > 5 ? 'high' : 'normal';
    const stockStatusIE = parseInt(stockIE) === 0 ? 'low' : parseInt(stockIE) > 5 ? 'high' : 'normal';

    if (this.data.images.length === 0) {
      this.data.images.push('/images/placeholder.jpg');
    }

    let oems = JSON.parse(JSON.stringify(this.data.oems));

    return {
      price,
      priceRetail,
      stock,
      stockStatus,
      stockFR,
      stockStatusFR,
      stockIE,
      stockStatusIE,
      currency,
      retailCurrency,
      succesMessage: '',
      index: null,
      loggedIn: this.$auth.loggedIn,
      company_name: this.$auth.loggedIn ? this.$auth.user.company : '',
      first_name: this.$auth.loggedIn ? this.$auth.user.firstname : '',
      last_name: this.$auth.loggedIn ? this.$auth.user.lastname : '',
      street: this.$auth.loggedIn ? this.$auth.user.street : '',
      house_number: this.$auth.loggedIn ? this.$auth.user.number : '',
      postal_code: this.$auth.loggedIn ? this.$auth.user.postal_code : '',
      city: this.$auth.loggedIn ? this.$auth.user.city : '',
      country: this.$auth.loggedIn ? this.$auth.user.country : '',
      phone: this.$auth.loggedIn ? this.$auth.user.phonenumber : '',
      email: this.$auth.loggedIn ? this.$auth.user.email : '',
      remarks: '',
      order_ref: '',
      oems
    }
  },
  methods: {
    quickEnquiry() {
      const body = {}
      if (this.$auth.loggedIn) {
        body.user_id = this.$auth.user.user_id
      } else {
        body.company = this.company_name
        body.firstname = this.first_name
        body.lastname = this.last_name
        body.street = this.street
        body.number = this.house_number
        body.postal_code = this.postal_code
        body.city = this.city
        body.country = this.country
        body.phonenumber = this.phone
        body.email = this.email
      }
      body.reference = this.order_ref
      body.note = this.remarks
      body.order_type = 'enquiry'
      body.order_rows = []
      const row = {}
      row.product_id = this.data.id
      row.quantity = 1
      row.price_per_piece = this.price
      row.currency = this.currency
      body.order_rows.push(row)

      portal
        .post('add-order', body, {
          'Content-Type': 'application/json'
        })
        .then((data) => {
          dataLayer.push({'event': 'quick_enquiry_send_detail'})

          this.$toast.success(this.$t('components.products.cart_send'))

          this.company_name = this.$auth.loggedIn ? this.$auth.user.company : ''
          this.first_name = this.$auth.loggedIn ? this.$auth.user.firstname : ''
          this.last_name = this.$auth.loggedIn ? this.$auth.user.lastname : ''
          this.street = this.$auth.loggedIn ? this.$auth.user.street : ''
          this.house_number = this.$auth.loggedIn ? this.$auth.user.number : ''
          this.postal_code = this.$auth.loggedIn
            ? this.$auth.user.postal_code
            : ''
          this.city = this.$auth.loggedIn ? this.$auth.user.city : ''
          this.country = this.$auth.loggedIn ? this.$auth.user.country : ''
          this.phone = this.$auth.loggedIn ? this.$auth.user.phonenumber : ''
          this.email = this.$auth.loggedIn ? this.$auth.user.email : ''
          this.remarks = ''
          this.order_ref = ''

          this.succesMessage = this.$t('components.products.cart_send')

          setTimeout(() => {
            this.$modal.hide('quickEnquiry')
          }, 2000)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async inCart() {
      await this.$store.dispatch(
        'addItemToCart',
        {
          product_id: this.data.id,
          quantity: 1
        }
      );

      if (this.$store.getters.getCartChanged) {
        this.$toast.success(this.$t('components.products.cart_added'))
      } else {
        this.$toast.error(this.$t('components.products.cart_error'))
      }
    },
    setIndex(index) {
      this.index = index
    },
    getYoutubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = url.match(regExp)

      return match && match[2].length === 11 ? match[2] : null
    },
    addToFavorites () {
      if (!this.$auth.loggedIn) {
        return;
      }

      let requestParams = {
        user_id: this.$auth.user.user_id,
        product_id: this.data.id
      };

      let headers = {
        'Content-Type': 'application/json'
      }

      if (this.isInFavorites) {
        portal.post(`favourites/remove`, requestParams, headers)
        .then((response) => {
          this.$store.commit('REMOVE_FAVORITE_ITEM', this.data.id);
        });
        return;
      }

      portal.post(`favourites/add`, requestParams, headers)
      .then((response) => {
        this.$store.commit('ADD_FAVORITE_ITEM', this.data.id);
      });
    },
    onClose () {
      this.index = null;
      document.body.style.overflow = null;
    }
  }
}
</script>

<style>
.price-details-wrapper {
  grid-gap: 15px;
}
.price-details-item {
  min-width: calc(50% - 15px);
  flex-grow: 1;
}

.blueimp-gallery,
.blueimp-gallery > .slides > .slide > .slide-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.blueimp-gallery > .slides > .slide > .slide-content {
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
}

.blueimp-gallery {
  position: fixed;
  z-index: 999999;
  overflow: hidden;
  background: #fff;
  opacity: 0;
  display: none;
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
}

.blueimp-gallery-carousel {
  position: relative;
  z-index: auto;
  margin: 1em auto;
  padding-bottom: 56.25%;
  box-shadow: 0 0 10px #000;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.blueimp-gallery-display {
  display: block;
  opacity: 1;
}

.blueimp-gallery > .slides {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.blueimp-gallery-carousel > .slides {
  position: absolute;
}

.blueimp-gallery > .slides > .slide {
  position: relative;
  float: left;
  height: 100%;
  text-align: center;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.blueimp-gallery > .slides > .slide-loading {
  /*background: url(../img/loading.gif) center no-repeat;*/
  background-size: 64px 64px;
}

.blueimp-gallery > .slides > .slide-loading > .slide-content {
  opacity: 0;
}

.blueimp-gallery > .slides > .slide-error {
  /*background: url(../img/error.png) center no-repeat;*/
}

.blueimp-gallery > .slides > .slide-error > .slide-content {
  display: none;
}

.blueimp-gallery > .next,
.blueimp-gallery > .prev {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -23px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #586666;
  text-decoration: none;
  text-align: center;
  background: #fff;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 3px solid #586666;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  opacity: 0.5;
  cursor: pointer;
  display: none;
}

.blueimp-gallery > .next {
  left: auto;
  right: 15px;
}

.blueimp-gallery > .close,
.blueimp-gallery > .title {
  position: absolute;
  top: 15px;
  left: 15px;
  margin: 0 40px 0 0;
  font-size: 20px;
  line-height: 30px;
  color: #586666;
  opacity: 0.8;
  display: none;
}

.blueimp-gallery > .close {
  padding: 15px;
  right: 15px;
  left: auto;
  margin: -15px;
  font-size: 80px;
  text-decoration: none;
  cursor: pointer;
}

.blueimp-gallery > .play-pause {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 15px;
  height: 15px;
  /*background: url(../img/play-pause.png) 0 0 no-repeat;*/
  cursor: pointer;
  opacity: 0.5;
  display: none;
}

.blueimp-gallery-playing > .play-pause {
  background-position: -15px 0;
}

.blueimp-gallery-controls > .close,
.blueimp-gallery-controls > .next,
.blueimp-gallery-controls > .play-pause,
.blueimp-gallery-controls > .prev,
.blueimp-gallery-controls > .title {
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.blueimp-gallery-left > .prev,
.blueimp-gallery-right > .next,
.blueimp-gallery-single > .next,
.blueimp-gallery-single > .play-pause,
.blueimp-gallery-single > .prev {
  display: none;
}

.blueimp-gallery > .close,
.blueimp-gallery > .next,
.blueimp-gallery > .play-pause,
.blueimp-gallery > .prev,
.blueimp-gallery > .slides > .slide > .slide-content {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.blueimp-gallery > .close:hover,
.blueimp-gallery > .next:hover,
.blueimp-gallery > .play-pause:hover,
.blueimp-gallery > .prev:hover,
.blueimp-gallery > .title:hover {
  color: #68715b;
  opacity: 1;
}

body:last-child .blueimp-gallery > .slides > .slide-error {
  /*background-image: url(../img/error.svg);*/
}

body:last-child .blueimp-gallery > .play-pause {
  width: 20px;
  height: 20px;
  background-size: 40px 20px;
  /*background-image: url(../img/play-pause.svg);*/
}

body:last-child .blueimp-gallery-playing > .play-pause {
  background-position: -20px 0;
}

* + html .blueimp-gallery > .slides > .slide {
  min-height: 300px;
}

* + html .blueimp-gallery > .slides > .slide > .slide-content {
  position: relative;
}

.blueimp-gallery > .indicator {
  position: absolute;
  top: auto;
  right: 15px;
  bottom: 15px;
  left: 15px;
  margin: 0 40px;
  padding: 0;
  list-style: none;
  text-align: center;
  line-height: 10px;
  display: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.blueimp-gallery > .indicator > li {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin: 6px 3px 0 3px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid transparent;
  background: #ccc;
  background: rgba(255, 255, 255, 0.25) center no-repeat;
  border-radius: 5px;
  box-shadow: 0 0 2px #000;
  opacity: 0.5;
  cursor: pointer;
}

* + html .blueimp-gallery > .indicator > li {
  display: inline;
}

.blueimp-gallery > .indicator > .active,
.blueimp-gallery > .indicator > li:hover {
  background-color: #fff;
  border-color: #fff;
  opacity: 1;
}

.blueimp-gallery > .indicator > li:after {
  opacity: 0;
  display: block;
  position: absolute;
  content: '';
  top: -5em;
  width: 75px;
  height: 75px;
  transition: transform 0.6s ease-out, opacity 0.4s ease-out;
  transform: translateX(-50%) translateY(0) translateZ(0);
  pointer-events: none;
}

.blueimp-gallery > .indicator > li:hover:after {
  opacity: 1;
  border-radius: 50%;
  background: inherit;
  transform: translateX(-50%) translateY(-5px) translateZ(0);
}

.blueimp-gallery > .indicator > .active:after {
  display: none;
}

.blueimp-gallery-controls > .indicator {
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.blueimp-gallery-single > .indicator {
  display: none;
}

.blueimp-gallery > .slides > .slide > .video-content > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.blueimp-gallery > .slides > .slide > .video-content > video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.blueimp-gallery > .slides > .slide > .video-content > iframe {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.blueimp-gallery > .slides > .slide > .video-playing > iframe {
  top: 0;
}

.blueimp-gallery > .slides > .slide > .video-content > a {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: -64px auto 0;
  width: 128px;
  height: 128px;
  /*background: url(../img/video-play.png) center no-repeat;*/
  opacity: 0.8;
  cursor: pointer;
}

.blueimp-gallery > .slides > .slide > .video-playing > a,
.blueimp-gallery > .slides > .slide > .video-playing > img {
  display: none;
}

.blueimp-gallery > .slides > .slide > .video-playing > video {
  display: block;
}

.blueimp-gallery > .slides > .slide > .video-loading > a {
  /*background: url(../img/loading.gif) center no-repeat;*/
  background-size: 64px 64px;
}

* + html .blueimp-gallery > .slides > .slide > .video-content {
  height: 100%;
}

* + html .blueimp-gallery > .slides > .slide > .video-content > a {
  left: 50%;
  margin-left: -64px;
}

.blueimp-gallery > .slides > .slide > .video-content > a:hover {
  opacity: 1;
}

body:last-child
  .blueimp-gallery
  > .slides
  > .slide
  > .video-content:not(.video-loading)
  > a {
  /*background-image: url(../img/video-play.svg);*/
}

.agile {
  position: relative;
}

.agile--ssr .agile__slides--cloned {
  display: none;
}

.agile--ssr .agile__slides > * {
  overflow: hidden;
  width: 0;
}

.agile--ssr .agile__slides > :first-child {
  width: 100%;
}

.agile--rtl .agile__actions,
.agile--rtl .agile__dots,
.agile--rtl .agile__slides,
.agile--rtl .agile__track {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.agile:active,
.agile :active,
.agile:focus,
.agile :focus {
  outline: none;
}

.agile__list {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.agile__track {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.agile__actions,
.agile__track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.agile__actions {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 0;
}

.agile--no-nav-buttons .agile__actions {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.agile__slides {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: unset;
  flex-shrink: unset;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.agile--disabled .agile__slides {
  display: block;
  width: 100%;
}

.agile__slide {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.agile__slide,
.agile__slide * {
  -webkit-user-drag: none;
}

.agile--fade .agile__slide {
  opacity: 0;
  position: relative;
  z-index: 0;
}

.agile--fade .agile__slide--active {
  opacity: 1;
  z-index: 2;
}

.agile--fade .agile__slide--expiring {
  opacity: 1;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  z-index: 1;
}

.agile__nav-button[disabled] {
  cursor: default;
}

.agile__nav-button {
  position: relative;
  margin-top: -65%;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 24px;
  cursor: pointer;
  border: none;
  background: none;
  color: #ccc;
  transition: all 0.3s;
}

.agile__nav-button:hover {
  color: #888;
}

.agile__dots {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  white-space: nowrap;
  margin-top: -20px;
  position: relative;
}

.agile__dot button {
  background-color: #ffe0d8;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 15px;
  font-size: 0;
  line-height: 0;
  margin: 0 2.5px;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 15px;
}

.agile__dot--current button,
.agile__dot:hover button {
  background-color: #ed2f25;
}

.vue-modal-resizer {
  display: block;
  overflow: hidden;
  position: absolute;
  width: 12px;
  height: 12px;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background: transparent;
  cursor: se-resize;
}

.vue-modal-resizer::after {
  display: block;
  position: absolute;
  content: '';
  background: transparent;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 10px solid #ddd;
  border-left: 10px solid transparent;
}

.vue-modal-resizer.clicked::after {
  border-bottom: 10px solid #369be9;
}

.v--modal-block-scroll {
  overflow: hidden;
  width: 100vw;
}

.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  opacity: 1;
}

.v--modal-overlay.scrollable {
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.v--modal-overlay .v--modal-background-click {
  width: 100%;
  min-height: 100%;
  height: auto;
}

.v--modal-overlay .v--modal-box {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.v--modal-overlay.scrollable .v--modal-box {
  margin-bottom: 2px;
}

.v--modal {
  background-color: white;
  text-align: left;
  border-radius: 3px;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  padding: 0;
}

.v--modal.v--modal-fullscreen {
  width: 100vw;
  height: 100vh;
  margin: 0;
  left: 0;
  top: 0;
}

.v--modal-top-right {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.overlay-fade-enter-active,
.overlay-fade-leave-active {
  transition: all 0.2s;
}

.overlay-fade-enter,
.overlay-fade-leave-active {
  opacity: 0;
}

.nice-modal-fade-enter-active,
.nice-modal-fade-leave-active {
  transition: all 0.4s;
}

.nice-modal-fade-enter,
.nice-modal-fade-leave-active {
  opacity: 0;
  transform: translateY(-20px);
}

.vue-dialog div {
  box-sizing: border-box;
}

.vue-dialog .dialog-flex {
  width: 100%;
  height: 100%;
}

.vue-dialog .dialog-content {
  flex: 1 0 auto;
  width: 100%;
  padding: 15px;
  font-size: 14px;
}

.vue-dialog .dialog-c-title {
  font-weight: 600;
  padding-bottom: 15px;
}

.vue-dialog .dialog-c-text {
}

.vue-dialog .vue-dialog-buttons {
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  border-top: 1px solid #eee;
}

.vue-dialog .vue-dialog-buttons-none {
  width: 100%;
  padding-bottom: 15px;
}

.vue-dialog-button {
  font-size: 12px !important;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 40px;
  height: 40px;
  color: inherit;
  font: inherit;
  outline: none;
}

.vue-dialog-button:hover {
  background: rgba(0, 0, 0, 0.01);
}

.vue-dialog-button:active {
  background: rgba(0, 0, 0, 0.025);
}

.vue-dialog-button:not(:first-of-type) {
  border-left: 1px solid #eee;
}

@media only screen and (max-width: theme('screens.tablet')) {
  .v--modal {
    width: 100% !important;
    left: 0 !important;
  }

  .v--modal iframe {
    width: 100% !important;
  }
}
</style>
