<template>
  <div>
    <img
      :src="slide.url"
      :alt="slide.title"
      :loading="slideIndex === 0 ? 'eager' : 'lazy'"
      class="w-full"
      width="1924"
      height="414" />
    <div class="container-fluid slide_content no-shadow flex">
      <div class="container my-auto flex flex-wrap">
        <div class="w-full tablet:w-1/2 tablet:ml-auto px-4">
          <div class="header">
            <header-h2
              :content="slide.title"
              color="white"
              mt="0"
              mb="0" />
            <header-h4
              :content="slide.caption"
              color="white"
              weight="light"
              mt="0"
              mb="4"/>
          </div>
        </div>
        <button-primary
          v-if="slide.description.length > 0"
          :text="slide.description"
          :link="slide.alt"
          :target="slide.target"
          class="w-full px-4 mt-8 text-center" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SliderItem',
  props: {
    slide: {
      default: () => {},
      required: true,
      type: Object
    },
    slideIndex: {
      required: true,
      type: Number
    }
  }
}
</script>
