<template>
  <section class="header__slider">
    <div class="container-fluid">
      <div class="flex">
        <div class="w-full">
          <client-only>
            <agile
              ref="headerSlider"
              :dots="false"
              :slides-to-show="1"
              :autoplay="true"
              :autoplay-speed="5000"
              :nav-buttons="true"
              :fade="true"
              :pauseOnHover="false">
              <template v-for="(slide, slideIndex) in slides">
                <a
                  v-if="slide.description.length && slide.alt"
                  :href="slide.alt"
                  :target="slide.target"
                  class="slide">
                  <slider-item
                    :slide="slide"
                    :slide-index="slideIndex" />
                </a>
                <slider-item
                  v-else
                  :slide="slide"
                  :slide-index="slideIndex"
                  class="slide" />
              </template>

              <template slot="prevButton">
                <span
                  v-if="slides.length > 1"
                  class="agile-nav-button agile-nav-button__prev">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </span>
                <span v-if="slides.length <= 1 "></span>
              </template>
              <template slot="nextButton">
                <span
                  v-if="slides.length > 1"
                  class="agile-nav-button agile-nav-button__next">
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </span>
                <span v-if="slides.length <= 1 "></span>
              </template>
            </agile>
          </client-only>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SliderItem from '../elements/SliderItem.vue';

export default {
  name: 'HeaderSlider',
  components: { SliderItem },
  props: {
    slides: {
      default: () => [],
      required: true,
      type: Array
    }
  }
}
</script>
