<template>
  <div class="inline">
    <div v-if="type === 'submit-form'">
      <button
        :class="`button button--tertiary ${extraClass}`"
        type="submit"
        :disabled="isDisabled" >
        {{ text }}
      </button>
    </div>
    <div v-else-if="type === 'login'">
      <button
        @click="$parent.login()"
        :class="`button button--tertiary ${extraClass}`"
        :disabled="isDisabled" >
        {{ text }}
      </button>
    </div>
    <div v-else-if="type === 'register'">
      <button
        @click="$parent.register()"
        :class="`button button--tertiary ${extraClass}`"
        :disabled="isDisabled" >
        {{ text }}
      </button>
    </div>
    <div v-else-if="type === 'submit'">
      <button
        @click="$parent.submit()"
        :class="`button button--tertiary ${extraClass}`"
        :disabled="isDisabled" >
        {{ text }}
      </button>
    </div>
    <div v-else>
      <nuxt-link
        :to="localePath(path)"
        :class="`button button--tertiary ${extraClass}`" >
        {{ text }}
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonTertiary',
  props: {
    type: {
      default: 'link',
      required: false,
      type: String
    },
    path: {
      default: '',
      required: false,
      type: String
    },
    text: {
      default: '',
      required: true,
      type: String
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
